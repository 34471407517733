import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { ActionChip, ChoiceChip, ChoiceChipGroup, TagChip, FilterChip } from '@entur/chip';
import { AddIcon, CarIcon } from '@entur/icons';
import { Label } from '@entur/typography';
import { Playground } from '~/components/playground/Playground';
import { ImportStatement } from '~/components/ImportStatement';
import PageHeader from '~/components/PageHeader';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'() => {\n  const [value, setValue] = React.useState(null)\n  return (\n    <div\n      style={{\n        display: \'grid\',\n        gridTemplateColumns: \'1fr 1fr\',\n        gridGap: \'1rem\',\n      }}\n    >\n      <div>\n        <Label>ChoiceChips</Label>\n        <ChoiceChipGroup\n          name=\"city\"\n          label=\"Velg setekategori\"\n          onChange={e => setValue(e.target.value)}\n          value={value}\n        >\n          <ChoiceChip value=\"standard\">Standard</ChoiceChip>\n          <ChoiceChip value=\"premium\">Premium</ChoiceChip>\n          <ChoiceChip value=\"sove\" disabled>\n            Sove (utsolgt)\n          </ChoiceChip>\n        </ChoiceChipGroup>\n      </div>\n      <div>\n        <Label>ActionChip</Label>\n        <div style={{ display: \'flex\' }}>\n          <ActionChip style={{ marginRight: \'1rem\' }}>Lagre</ActionChip>\n          <ActionChip style={{ marginRight: \'1rem\' }}>\n            Legg til <AddIcon aria-hidden />\n          </ActionChip>\n        </div>\n      </div>\n      <div>\n        <Label>TagChip</Label>\n        <TagChip>Buss</TagChip>\n      </div>\n      <div>\n        <Label>FilterChip</Label>\n        <FilterChip value=\"mandag\">Mandag</FilterChip>\n      </div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      ActionChip,
      ChoiceChip,
      ChoiceChipGroup,
      TagChip,
      FilterChip,
      AddIcon,
      CarIcon,
      Label,
      Playground,
      ImportStatement,
      PageHeader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = React.useState(null);
        return <div style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridGap: '1rem'
        }}>
        <div>
          <Label mdxType="Label">ChoiceChips</Label>
          <ChoiceChipGroup name="city" label="Velg setekategori" onChange={e => setValue(e.target.value)} value={value} mdxType="ChoiceChipGroup">
            <ChoiceChip value="standard" mdxType="ChoiceChip">Standard</ChoiceChip>
            <ChoiceChip value="premium" mdxType="ChoiceChip">Premium</ChoiceChip>
            <ChoiceChip value="sove" disabled mdxType="ChoiceChip">
              Sove (utsolgt)
            </ChoiceChip>
          </ChoiceChipGroup>
        </div>
        <div>
          <Label mdxType="Label">ActionChip</Label>
          <div style={{
              display: 'flex'
            }}>
            <ActionChip style={{
                marginRight: '1rem'
              }} mdxType="ActionChip">Lagre</ActionChip>
            <ActionChip style={{
                marginRight: '1rem'
              }} mdxType="ActionChip">
              Legg til <AddIcon aria-hidden mdxType="AddIcon" />
            </ActionChip>
          </div>
        </div>
        <div>
          <Label mdxType="Label">TagChip</Label>
          <TagChip mdxType="TagChip">Buss</TagChip>
        </div>
        <div>
          <Label mdxType="Label">FilterChip</Label>
          <FilterChip value="mandag" mdxType="FilterChip">Mandag</FilterChip>
        </div>
      </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "actionchip"
    }}>{`ActionChip`}</h3>
    <ImportStatement imports="ActionChip" mdxType="ImportStatement" />
    <Props of={ActionChip} mdxType="Props" />
    <p><inlineCode parentName="p">{`ActionChip`}</inlineCode>{` brukes på steder hvor man skal gjøre en handling som ikke krever like mye oppmerksomhet som vanlige knapper gir.
Alternativt kan man bruke tertiærknapp.`}</p>
    <h3 {...{
      "id": "choicechip"
    }}>{`ChoiceChip`}</h3>
    <ImportStatement imports="ChoiceChip" mdxType="ImportStatement" />
    <Props of={ChoiceChip} mdxType="Props" />
    <p><inlineCode parentName="p">{`ChoiceChip`}</inlineCode>{` og `}<inlineCode parentName="p">{`ChoiceChipGroup`}</inlineCode>{` er alternativer for vanlige radioknapper.`}</p>
    <h3 {...{
      "id": "choicechipgroup"
    }}>{`ChoiceChipGroup`}</h3>
    <ImportStatement imports="ChoiceChipGroup" mdxType="ImportStatement" />
    <Props of={ChoiceChipGroup} mdxType="Props" />
    <h3 {...{
      "id": "tagchip"
    }}>{`TagChip`}</h3>
    <ImportStatement imports="TagChip" mdxType="ImportStatement" />
    <Props of={TagChip} mdxType="Props" />
    <h3 {...{
      "id": "filterchip"
    }}>{`FilterChip`}</h3>
    <ImportStatement imports="FilterChip" mdxType="ImportStatement" />
    <Props of={FilterChip} mdxType="Props" />
    <h2 {...{
      "id": "retningslinjer-for-bruk"
    }}>{`Retningslinjer for bruk`}</h2>
    <h3 {...{
      "id": "action-chips"
    }}>{`Action chips`}</h3>
    <p>{`Disse oppfører seg som `}<strong parentName="p">{`handlingsknapper`}</strong>{` som skal brukes når handlingen(e) er direkte knyttet/relatert til hovedinnholdet.
Det er oftest flere handlinger, f.eks. ‘Endre’, ‘Slett’, ‘Kjøp på nytt’ osv. men i tabeller kan også action chips benyttes alene.
Siden Action chips aktiverer en handling, er ingen av dem forhåndsvalgt. Hvis man kun hr én action kan man alternativt benytte Tertiærknapp.
Action chip kan ikke være inaktiv, hvis innholdet mangler må man allikevel navigere til ønsket side/funksjon,
og vise en melding om hvorfor dette ikke er tilgjengelig.`}</p>
    <h3 {...{
      "id": "choice-chips"
    }}>{`Choice chips`}</h3>
    <p>{`Disse skal benyttes for å tilby et `}<strong parentName="p">{`valg`}</strong>{` mellom flere ulike opsjoner.
De er ofte direkte knyttet til info som vises ovenfor i flaten (f.eks en reiserute i kart eller en billettkategori),
og viser også ofte mer info/forklaring knyttet til valgt Choice chip under raden med chips. Funksjonen er samme som i radio buttons,
én verdi er forhåndsvalgt og man kan velge én av de andre. Inaktive Choice chips kan kun benyttes hvis man vil vise noe som vanligvis er tilgjengelig,
men akkurat nå ikke er det, f.eks. hvis et setevalg er utsolgt. Årsaken til at et valg ikke er tilgjengelig må alltid beskrives på knappen,
og inaktivt design skal være godt lesbart og ikke tonet ned.`}</p>
    <h3 {...{
      "id": "filter-chips"
    }}>{`Filter chips`}</h3>
    <p>{`Selector chips benyttes til `}<strong parentName="p">{`forhåndsdefinerte valg`}</strong>{`, f.eks. mellom aktuelle kategorier.
De tilbyr tydelig avgrensede alternativer i et kompakt område. De er et godt alternativ toggle-knapper eller checkboxes.`}</p>
    <p>{`Funksjonelt er dette checkboxer; ingen er valgt by default, og det er mulig å velge flere eller ingen.
Nytt design med tom/full check, som tydeligere indikerer at det å trykke på chip’en er et mulig valg er underveis.`}</p>
    <h3 {...{
      "id": "tag-chips"
    }}>{`Tag chips`}</h3>
    <p>{`Filter chips dukker opp når de aktiveres, og kan deaktiveres med krysset på selve taggen,
eller ved å ‘huke vekk’ valget inne i en meny. De kan organiseres i en horisontalt på rekke og rad, tilnyttet flere søkefelter.
Samme design benyttes også til å vise valg i dropdown med multiple choice-funksjon.
Man kan velge om man vil vise tags utenfor eller inne i søkefeltet/søkefeltene.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      